import React from 'react';
import { Stack, Typography } from '@mui/material';
import VendorDetails from '../VendorDetails';
import QuantityChangeDetails from './QuantityChangeDetails';
import { ArrowForwardRounded } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import ItemChipSection from '../ItemChipSection';

function OptimizationBody(props) {
    const theme = useTheme();

    if (props.recommendation.status === 'custom_swap') {
        return (
            <Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <ItemName color={theme.palette.subtext.main} name={props.item.name} />
                    <ArrowForwardRounded size='large' style={{ color: '#0000004D' }} />
                    <ItemName color={theme.palette.blue.main} name={props.recommendation.recommended_item.name} />
                </Stack>
            </Stack>
        )
    }


    return (
        <Stack spacing={1}>
            <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.item.name}</Typography>
            { (props.recommendation.type === 'update' || props.recommendation.status === 'custom_update') && props.recommendation.status !== 'custom_removal' && 
                <QuantityChangeDetails item={props.item} recommendation={props.recommendation} />
            }
            { (props.recommendation.type === 'addition' || props.recommendation.type === 'removal' || props.recommendation.status === 'custom_removal') && props.recommendation.status !== 'custom_update' &&
                <VendorDetails item={props.item} recommendation={props.recommendation} />
            }
            { props.recommendation.status === 'open' &&
                <ItemChipSection item={props.item} recommendation={props.recommendation} yearlyUsage={props.yearlyUsage} />
            }
        </Stack>
    )
}

function ItemName(props) {
    return (
        <Typography variant='normal' sx={{ 
            fontSize: '12px', 
            color: props.color,     
            textOverflow: 'ellipsis', 
            overflow: 'hidden', 
            WebkitLineClamp: 2, 
            WebkitBoxOrient: 'vertical', 
            display: '-webkit-box',
            width: '45%',
            textAlign: 'center'
        }}>
            {props.name}
        </Typography>
    );
}


export default OptimizationBody;
